import React from "react";
import logo from "../../assets/images/logo.svg";
import "./Home.css";

const Home = () => {
  return (
    <div className="Home">
      <header className="Home-header">
        <img src={logo} className="Home-logo" alt="logo" />
        <p className="Home-text">
          Rima Aí, novo App de batalhas de rimas online. Batalha, suba no
          ranking e divirta-se
        </p>
        <a
          className="Home-link"
          href="https://rimaai.page.link/5y6Q"
          target="_blank"
          rel="noopener noreferrer"
        >
          BAIXE AQUI
        </a>
      </header>
    </div>
  );
};

export default Home;
