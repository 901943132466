import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Home from "./pages/Home/Home";
import IA from "./pages/IA/IA";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/bot">
          <IA />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
