import React, { useState } from "react";
import axios from "axios";

import "./IA.css";
import logo from "../../assets/images/logo.svg";
import Spinner from "../../components/Spinner/Spinner";

const IA = () => {
  const [ataque, setAtaque] = useState("");
  const [resposta, setResposta] = useState("");
  const [loading, setLoading] = useState(false);

  const checkAPI = () => {
    if (ataque === "") {
      alert("Ataque está em branco");
      return false;
    }
    if (resposta === "") {
      alert("Resposta está em branco");
      return false;
    }
    if (!ataque.includes("https://firebasestorage")) {
      alert("URL ataque não válida");
      return false;
    }
    if (!resposta.includes("https://firebasestorage")) {
      alert("URL resposta não válida");
      return false;
    }

    setLoading(true);
    axios
      .post("https://ia.rimaai.com.br/battle", {
        ataque,
        resposta,
      })
      .then((r) => {
        setLoading(false);
        // setAtaque(false);
        // setResposta(false);
        alert(r.data);
      })
      .catch((e) => {
        setLoading(false);
        // setAtaque(false);
        // setResposta(false);
        if (e.response) {
          alert(e.response.data);
        }
      });

    return true;
  };

  return (
    <div className="IA">
      <img src={logo} className="IA-logo" alt="logo" />
      <p className="IA-text">
        Coloque a url do audio de ataque e a url do audio de resposta
      </p>
      <div className="IA-input-container">
        <input
          type="text"
          className="IA-input"
          placeholder="Ataque ULR"
          value={ataque}
          onChange={(e) => setAtaque(e.target.value)}
        />
        <input
          type="text"
          className="IA-input"
          placeholder="Resposta ULR"
          value={resposta}
          onChange={(e) => setResposta(e.target.value)}
        />
      </div>
      <div className="Home-link" role="presentation" onClick={checkAPI}>
        Ver resultado
      </div>

      {loading && (
        <div className="IA-spinner-ontainer">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default IA;
