import React from "react";

import "normalize.css";
import Router from "./Router";

function App() {
  return <Router />;
}

export default App;
